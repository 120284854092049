import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'td[ln-table-td]',
  standalone: true,
  template: '<ng-content/>',
  styles: `
    :host {
      padding: var(--table-padding, 1rem);
    }
  `,
})
export class TableTdComponent {}
