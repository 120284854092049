import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th[ln-table-th]',
  standalone: true,
  imports: [],
  template: '<ng-content/>',
  styles: `
    :host {
      font-weight: bold;
      text-align: left;
      text-transform: capitalize;
      background-color: var(--header-color);
      padding: var(--table-padding, 1rem);
      color: white;
    }
  `,
  // :host:first-child {
  //   border-top-left-radius: 10px;
  // }

  // :host:last-child {
  //   border-top-right-radius: 10px;
  // }
})
export class TableThComponent {}
