<table cdk-table #cdkTable multiTemplateDataRows [dataSource]="dataSource()" [trackBy]="trackById">
  <ng-content />

  <tr *cdkHeaderRowDef="displayHeaders(); sticky: true" cdk-header-row></tr>

  <tr *cdkRowDef="let row; columns: displayHeaders()" cdk-row></tr>
  <tr *cdkRowDef="let row; columns: expandedHeaders()" cdk-row></tr>

  <ng-container cdkColumnDef="expand">
    <th *cdkHeaderCellDef cdk-header-cell ln-table-th>&nbsp;</th>
    <td *cdkCellDef="let element" cdk-cell ln-table-td>
      <div
        class="arrow-icon"
        [class.expanded]="selectionModel.isSelected(element.id)"
        (click)="selectionModel.toggle(element.id)"
      ></div>
    </td>
  </ng-container>

  <tr *cdkNoDataRow>
    <td [attr.colspan]="displayHeaders().length">
      @if (loading()) {
        <div class="progress">
          <div class="progress-bar indeterminate"></div>
        </div>
        <div class="placeholder-text">Please wait...</div>
      } @else {
        <div class="placeholder-text">{{ emptyPlaceholder() }}</div>
      }
    </td>
  </tr>
</table>
