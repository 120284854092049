@if (!inEditMode) {
  <span class="input-text" (click)="onDisplayAreaClicked()">
    {{ getPlaceHolder() }}
  </span>
}

@if (inEditMode) {
  <span class="editable-display-area-input">
    <locumsnest-input-field
      [hasBorder]="true"
      [ngModel]="inputText"
      [hasBorder]="hasBorder"
      [type]="type"
      [errorMsg]="errorMsg"
      (inputEntered)="onChangeArea($event)"
      (blur)="onBlur($event)"
    />
  </span>
}
