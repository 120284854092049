import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';

@Component({
  selector: 'ln-copy-button',
  standalone: true,
  templateUrl: './copy-button.component.html',
  styleUrl: './copy-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'copyToClipboard($event)',
    '(transitionend)': 'resetText()',
  },
})
export class CopyButtonComponent {
  copyTextButton = input('copy');
  copiedTextButton = input('copied!');
  textToCopy = input('');
  alwaysShowCopyText = input(false);

  private clipboard = inject(Clipboard);

  copiedText = signal(this.copyTextButton());

  copyToClipboard() {
    this.copiedText.set(this.copiedTextButton());
    this.clipboard.copy(this.textToCopy());

    // reset after 5s
    if (this.alwaysShowCopyText()) {
      setTimeout(() => this.resetText(), 5000);
    }
  }

  resetText() {
    this.copiedText.set(this.copyTextButton());
  }
}
