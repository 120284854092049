import {
  animate,
  AnimationBuilder,
  AnimationMetadata,
  AnimationPlayer,
  style,
} from '@angular/animations';
import { AfterViewInit, Directive, ElementRef, inject, input, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[lnExpandedRow]',
})
export class ExpandedRowDirective implements AfterViewInit {
  colspan = input.required<number>();
  id = input.required<number>();
  elementRef = inject(ElementRef);
  renderer = inject(Renderer2);
  animationBuilder = inject(AnimationBuilder);
  private scrollHeight = 0;
  private player: AnimationPlayer;

  ngAfterViewInit() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'colspan', this.colspan().toString());
    this.scrollHeight = this.elementRef.nativeElement.firstChild.scrollHeight;
    this.renderer.setStyle(this.elementRef.nativeElement.firstChild, 'overflow', 'hidden');
    this.show('void');
  }

  collapseRow() {
    this.show('collapsed');
  }

  expandRow() {
    this.show('expanded');
  }

  private show(triggerState: 'collapsed' | 'expanded' | 'void') {
    if (this.player) {
      this.player.destroy();
    }

    let metadata: AnimationMetadata[];

    if (triggerState === 'void') {
      this.renderer.setStyle(this.elementRef.nativeElement.firstChild, 'height', '0px');
      this.renderer.setStyle(this.elementRef.nativeElement.firstChild, 'min-height', '0');
      this.renderer.setStyle(this.elementRef.nativeElement.firstChild, 'opacity', '0.2');
      this.renderer.setStyle(this.elementRef.nativeElement, 'padding', '0');
      return;
    }

    if (triggerState === 'collapsed') {
      this.renderer.setStyle(this.elementRef.nativeElement, 'padding', '0');
      this.renderer.setStyle(this.elementRef.nativeElement.firstChild, 'overflow', 'hidden');
      metadata = this.slideOut();
    } else {
      this.renderer.removeStyle(this.elementRef.nativeElement, 'padding');
      this.renderer.removeStyle(this.elementRef.nativeElement.firstChild, 'overflow');
      metadata = this.slideIn();
    }

    const factory = this.animationBuilder.build(metadata);
    const player = factory.create(this.elementRef.nativeElement.firstChild);

    player.play();
  }

  private slideIn(): AnimationMetadata[] {
    return [
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      style({ height: `${this.scrollHeight}px`, opacity: 1 }),
    ];
  }

  private slideOut(): AnimationMetadata[] {
    return [
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      style({ height: '0px', minHeight: '0', opacity: 0.2 }),
    ];
  }
}
